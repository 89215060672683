import { React, useState, useEffect, } from 'react';
import {
  ChakraProvider,
  Box,
  Link,
  Grid,
  theme,
  Button,
  Flex,
  IconButton
} from '@chakra-ui/react';
import bgDesktop from './bg-desktop.mp4';
import bgMobile from './bg-mobile.png';
import headerLogo from './textlogo.png';
import { Icon } from '@chakra-ui/react'
import { FaDiscord, FaBook, FaBlogger, FaArrowRight } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

import bgDesktopLoading from './loadingbg.png';
import animoca from './fluid vc logos/animoca.png';
import bloccelerate from './fluid vc logos/bloccelerate.png';
import builder from './fluid vc logos/builder.png';
import cms from './fluid vc logos/cms.png';
import ivc from './fluid vc logos/ivc.png';
import maelstrom from './fluid vc logos/maelstrom.png';
import optic from './fluid vc logos/optic.png';
import relayer from './fluid vc logos/relayer.png';
import rocktree from './fluid vc logos/rocktree.png';
import stake from './fluid vc logos/stake.png';
import veil from './fluid vc logos/veil.png';
import wise3 from './fluid vc logos/wise3.png';
import ottersecLogo from './ottersec-logo.svg';
import immunifiLogo from './immunifi-logo-white.svg';

function App() {

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Box fontSize="xl">


        {width > 1200 ?

          (
            <Grid minH="100vh" style={{
              backgroundColor: "#000", backgroundImage: `url(${bgDesktopLoading})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundColor: "#000"
            }}>
              <Box style={{ zIndex: 99, marginTop: "60px", marginLeft: "100px", marginRight: "100px", position: "relative" }}>
                <img style={{ zIndex: 99, maxWidth: "80vw", width: "600px", marginBottom: "20px" }} src={headerLogo} />
                <p style={{ zIndex: 99, color: "#3aff16", fontSize: 24, marginBottom: "20px", fontFamily: "'Orbitron', sans-serif", letterSpacing: "8px" }}>
                  The Native Stablecoin of Fuel
                </p>
                <Flex align="center" gap={4}>
                  <p style={{
                    color: "#fff",
                    fontSize: "16px",
                    fontFamily: "'Orbitron', sans-serif",
                    letterSpacing: "4px"
                  }}>
                    SECURED BY
                  </p>
                  <img
                    src={ottersecLogo}
                    alt="OtterSec"
                    style={{
                      height: "24px",
                      filter: "brightness(0) invert(1)"
                    }}
                  />
                  <Box
                    height="24px"
                    width="1px"
                    backgroundColor="rgba(255, 255, 255, 0.5)"
                  />
                  <img
                    src={immunifiLogo}
                    alt="Immunifi"
                    style={{
                      height: "24px",
                      filter: "brightness(0) invert(1)"
                    }}
                  />
                </Flex>
              </Box>
              <Flex
                gap={4}
                position="absolute"
                top="60px"
                right="60px"
                zIndex={100}
              >
                <Link href='https://mira.ly/swap' isExternal>
                  <Button
                    size="lg"
                    variant="outline"
                    color="#E2E8F0"
                    borderWidth="1px"
                    borderColor="white"
                  >
                    Buy USDF
                  </Button>
                </Link>
                <Link href='https://app.fluidprotocol.xyz' isExternal>
                  <Button
                    size="lg"
                    variant="solid"
                    backgroundColor="#05d951"
                    _hover={{ bg: '#05c74c' }}
                    color="#000"
                  >
                    Launch App &nbsp; <Icon as={FaArrowRight}></Icon>
                  </Button>
                </Link>
              </Flex>
              <Box style={{ position: "absolute", bottom: 25, left: 0, right: 0, width: "400px", margin: "0 auto" }}>
                <Box style={{ width: "100%", display: "block", justifySelf: "center" }}>
                  <Flex style={{ justifyContent: "space-between" }}>
                    <IconButton variant="link" onClick={() => window.open("https://discord.com/invite/fSZKERH9YJ", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaDiscord} />} />
                    <IconButton variant="link" onClick={() => window.open("https://twitter.com/Fluid_Protocol", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaXTwitter} />} />
                    <IconButton variant="link" onClick={() => window.open("https://fluidprotocol.substack.com/", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaBlogger} />} />
                    <IconButton variant="link" onClick={() => window.open("https://docs.hydrogenlabs.xyz/fluid-protocol-community", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaBook} />} />
                  </Flex>
                </Box>
              </Box>
              <video poster={bgDesktopLoading} autoPlay={true} muted={true} loop={true} style={{ minHeight: "100%", minWidth: "100%", objectFit: "cover", width: "100vw", height: "100vh", position: "fixed", top: 0, left: 0 }}>
                <source src={bgDesktop} type="video/mp4" />
              </video>

              <Box mt={6} mb={6} px={4} style={{
                zIndex: 99,
                position: "absolute",
                bottom: "15%",
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
                maxWidth: "1200px"
              }}>
                <Flex justify="center" align="start" gap={20}>
                  {/* Left Column - Backers */}
                  <Box flex="1">
                    <Box mb={8} textAlign="center">
                      <p style={{
                        color: "#fff",
                        fontSize: "24px",
                        fontFamily: "'Orbitron', sans-serif",
                        letterSpacing: "4px"
                      }}>
                        BACKED BY
                      </p>
                    </Box>
                    <Flex direction="column" gap={10} align="center">
                      <Flex wrap="nowrap" gap={12} justify="center" align="center">
                        <img src={animoca} alt="Animoca Brands" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                        <img src={bloccelerate} alt="Bloccelerate Labs" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                        <img src={builder} alt="Builder Capital" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                        <img src={cms} alt="CMS" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                        <img src={ivc} alt="IVC" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                        <img src={maelstrom} alt="Maelstrom Crypto" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                      </Flex>
                      <Flex wrap="nowrap" gap={12} justify="center" align="center">
                        <img src={optic} alt="Optic Capital" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                        <img src={relayer} alt="Relayer Capital" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                        <img src={rocktree} alt="Rocktree Capital" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                        <img src={stake} alt="Stake Capital" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                        <img src={veil} alt="Veil" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                        <img src={wise3} alt="Wise3" style={{ height: "28px", filter: "brightness(0) invert(1)" }} />
                      </Flex>
                    </Flex>
                  </Box>

                </Flex>
              </Box>
            </Grid>
          )
          :
          (
            <Box minH="100vh" style={{
              backgroundImage: `url(${bgMobile})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundColor: "#000",
              position: "relative"
            }}>
              <img style={{ zIndex: 99, maxWidth: "80vw", width: "400px", paddingTop: "40px", margin: "0 auto", marginBottom: "20px" }} src={headerLogo} />
              <p style={{ zIndex: 99, color: "#3aff16", fontSize: 12, textAlign: "center", marginBottom: "20px", fontFamily: "'Orbitron', sans-serif", letterSpacing: "4px" }}>The Native Stablecoin of Fuel</p>
              <Flex
                align="center"
                gap={3}
                justify="center"
                marginTop="20px"
                marginBottom="20px"
              >
                <p style={{
                  zIndex: 99,
                  color: "#fff",
                  fontSize: "12px",
                  fontFamily: "'Orbitron', sans-serif",
                  letterSpacing: "2px",
                }}>
                  SECURED BY
                </p>
                <img
                  src={ottersecLogo}
                  alt="OtterSec"
                  style={{
                    height: "16px",
                    filter: "brightness(0) invert(1)"
                  }}
                />
                <Box
                  height="16px"
                  width="1px"
                  backgroundColor="rgba(255, 255, 255, 0.2)"
                />
                <img
                  src={immunifiLogo}
                  alt="Immunifi"
                  style={{
                    height: "16px",
                    filter: "brightness(0) invert(1)"
                  }}
                />
              </Flex>
              <Flex
                gap={4}
                position="absolute"
                top="25%"
                left="0"
                right="0"
                zIndex={100}
                direction="row"
                px={4}
              >

                <Link href='https://mira.ly/swap' isExternal width="50%">
                  <Button
                    variant="outline"
                    color="#fff"
                    size="sm"
                    width="100%"
                  >
                    Buy USDF
                  </Button>
                </Link>
                <Link href='https://app.fluidprotocol.xyz' isExternal width="50%">
                  <Button
                    variant="outline"
                    color="#fff"
                    size="sm"
                    width="100%"
                  >
                    Launch App &nbsp; <Icon as={FaArrowRight}></Icon>
                  </Button>
                </Link>
              </Flex>
              <Box mt={6} mb={6} px={4} style={{
                zIndex: 99,
                position: "absolute",
                bottom: "4%",
                left: "0",
                width: "100%"
              }}>
                <Box mb={8} textAlign="center">
                  <p style={{
                    color: "#fff",
                    fontSize: "16px",
                    fontFamily: "'Orbitron', sans-serif",
                    letterSpacing: "4px"
                  }}>
                    BACKED BY
                  </p>
                </Box>
                <Flex direction="column" gap={6}>
                  <Flex wrap="nowrap" gap={4} justify="center" align="center">
                    <img src={animoca} alt="Animoca Brands" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                    <img src={bloccelerate} alt="Bloccelerate Labs" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                    <img src={builder} alt="Builder Capital" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                    <img src={cms} alt="CMS" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                  </Flex>
                  <Flex wrap="nowrap" gap={4} justify="center" align="center">
                    <img src={ivc} alt="IVC" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                    <img src={maelstrom} alt="Maelstrom Crypto" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                    <img src={optic} alt="Optic Capital" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                    <img src={relayer} alt="Relayer Capital" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                  </Flex>
                  <Flex wrap="nowrap" gap={4} justify="center" align="center">
                    <img src={rocktree} alt="Rocktree Capital" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                    <img src={stake} alt="Stake Capital" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                    <img src={veil} alt="Veil" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                    <img src={wise3} alt="Wise3" style={{ height: "20px", filter: "brightness(0) invert(1)" }} />
                  </Flex>
                </Flex>
                
                <Box mt={8} style={{ width: "100%", display: "block", justifySelf: "center" }}>
                  <Flex style={{ justifyContent: "space-between", maxWidth: "400px", margin: "0 auto" }}>
                    <IconButton variant="link" onClick={() => window.open("https://discord.com/invite/fSZKERH9YJ", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaDiscord} />} />
                    <IconButton variant="link" onClick={() => window.open("https://twitter.com/Fluid_Protocol", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaXTwitter} />} />
                    <IconButton variant="link" onClick={() => window.open("https://fluidprotocol.substack.com/", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaBlogger} />} />
                    <IconButton variant="link" onClick={() => window.open("https://docs.hydrogenlabs.xyz/fluid-protocol-community", '_blank')} icon={<Icon style={{ zIndex: 99, color: "#fff", fontSize: "32px", cursor: "pointer" }} as={FaBook} />} />
                  </Flex>
                </Box>
              </Box>
            </Box>
          )

        }


      </Box>
    </ChakraProvider>
  );
}

export default App;
